<template>
  <v-scale-transition>
    <kits-panel :kits-items="subscriberGroup">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        Certificates
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
    }
  },
  computed: {
    subscriberGroup: function () {
      const menu = [
        {
          icon: 'mdi-certificate',
          path: '/lead',
          title: 'Lead',
          desc: 'Learning Education & Development.',
        },
        {
          icon: 'mdi-certificate',
          path: '/dcp_cert',
          title: 'DCP',
          desc: 'Dealer Certification Program.',
        },
      ]

      return menu
    },
  },
}
</script>
